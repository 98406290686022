import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AlertComponent } from '../components/shared/alert/alert.component';

import { JwtInterceptor } from '../components/_services/jwt.interceptor';
import { ErrorInterceptor } from '../components/_services/error.interceptor';

import { NbCardModule, NbSidebarModule, NbListModule, NbLayoutModule, NbThemeModule, NbActionsModule, NbMenuModule, NbContextMenuModule, NbDatepickerModule, NbDialogModule, NbWindowModule, NbToastrModule, NbChatModule, NbUserModule } from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { ThemeModule } from '../components/shared/@theme/theme.module';
import { LayoutService } from '../components/shared/@core/utils/layout.service';
import { NbAccessChecker, NbRoleProvider, NbAclService } from '@nebular/security';
import { CoreModule } from 'src/components/shared/@core/core.module';

import { FormsModule } from '@angular/forms';
import { CORPORATE_THEME } from '../components/shared/@theme/styles/theme.corporate';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      NbThemeModule.forRoot({ name: 'corporate' },[ CORPORATE_THEME ]),
      NbLayoutModule,
      NbEvaIconsModule,
      //ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot(),
    CoreModule.forRoot(),
    NbListModule,
    NbCardModule, NbUserModule,
  ],
  declarations: [
      AppComponent,
      AlertComponent,
  ],
  providers: [
        LayoutService, NbAccessChecker, NbAclService, 
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
