import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../components/_services/auth.service';
import { User } from '../components/_models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  currentUser: User;
  title = "elevenEdi"
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
  ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

      let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#c5e173",
             text: "#ffffff"
           },
           button: {
             background: "#88bf06",
             text: "#ffffff"
           }
         },
         theme: "classic",
         content: {
           message: "Acest website folosește Cookies pentru a vă îmbunătăți experiența online. Prin acceptarea sau continuarea navigării, sunteți de acord cu tehnologiile și informațiile folosite. Mai multe detalii le puteți afla în",
           dismiss: "ACCEPT",
           link: "Termeni și condiții",
           href: "/terms",
           target: "_self"
         }
       });
  }
}
